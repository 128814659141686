<template>
  <el-container>
    <el-header :style="{backgroundColor: configset.framecolor}">
      <div class="flex flex-sb font-20" style="color: white">
        <div>
          <i class="el-icon-chat-dot-square iconft"></i>
          <span>{{ cserverInfo.projname }}</span>
        </div>
        <div class="flex">
          <div style="color: #ff9900">
            <i class="el-icon-user iconft"></i>
            <span><a @click="toclient" target="_blank">人工服务</a></span>
          </div>
          <div style="margin-left: 20px;">
            <i class="el-icon-s-custom iconft"></i>
            <span><a @click="toRobot" target="_blank">自助咨询</a></span>
          </div>
          <div style="margin-left: 20px">
            <i class="el-icon-chat-dot-square iconft"></i>
            <span><a @click="leavingMessage" target="_blank">我要留言</a></span>
          </div>
        </div>
      </div>
    </el-header>
    <el-container style="margin-top: 60px !important;height: calc(100% - 60px)">
      <el-aside width="140px;">
        <div class="pad15">
          <el-avatar style="text-align: center"
            :src="cserverInfo.headimgurl"
            shape="square"
            :size="100"
          ></el-avatar>
          <div style="line-height: 20px;">
            <div>
              <span>昵称</span>
              <span class="mar-l10">{{ cserverInfo.nickname }}</span>
            </div>
<!--            <div>
              <span>电话</span>
              <span class="mar-l10">{{ cserverInfo.mobile }}</span>
            </div>
            <div>
              <span>QQ</span>
              <span class="mar-l10">{{ cserverInfo.qq }}</span>
            </div>-->
          </div>
        </div>
      </el-aside>
      <el-main class="main">
        <TalkView @getCserverInfo="getCserverInfo" :tokenkey="tokenkey" :customerId="customerId" style="margin-top: 42px;"></TalkView>
      </el-main>
      <el-aside width="210px" class="right-aside">
        <div class="title">关于我们</div>
        <div style="padding: 10px;">
          <div style="height: 200px;width: 100%;">
            <el-carousel height="200px">
              <el-carousel-item v-for="item in configset.aboutCarousels" :key="item">
                <img :src="item" style="object-fit: cover; width: 100%;height: 100%;"></img>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div style="margin-top: 20px" v-html="configset.aboutIntroduce">
          </div>
        </div>
      </el-aside>
    </el-container>
  </el-container>
</template>
<script>
  import TalkView from './components/TalkView'
  import {
    getConfigset,
    decryptTokenkey
  } from "@/apis/webstocket";
  export default {
    name: "index",
    components: {
      TalkView,
    },
    data() {
      return {
        tokenkey: this.$route.query.tokenkey,
        customerId: this.$route.query.s,
        imgs: "",
        imgsVisible: false,
        projid:null,

        configset: {
          aboutCarousel: null,
          aboutCarousels: [],
          aboutIntroduce: null,
          framecolor: null,
          notonlinetext: null,
          notonlinebtncolor: null,
          autoofftext: null,
          autoofftime: null
        },
        cserverInfo: {
          nickname: "智能客服",
          headimgurl: "/images/default.png",
        },
      }
    }
    ,created() {
      window.top.document.title = '';
      this.decryptTokenkey();
    }
    ,methods:{
      decryptTokenkey() { //地址栏参数解码
        let data = {
          tokenkey: this.$route.query.tokenkey
        }
        decryptTokenkey(data).then(res => {
          this.projid = res.retdata.projid;
          console.log(this.projid)
          this.getConfigset()
        })
      },getConfigset() {
        let data = {
          projid: this.projid
        }
        getConfigset(data).then(res => {
          this.configset = res.retdata;
          if (this.configset.aboutCarousel) {
            this.configset.aboutCarousels = this.configset.aboutCarousel.split(",");
          }
        })
      }, toRobot() {
      this.$router.push(
        "/vclient/robot?tokenkey=" + this.getEcodeUrl(this.$route.query.tokenkey));
    }
    , toclient() {
      this.$router.push(
        "/vclient/client?tokenkey=" + this.getEcodeUrl(this.$route.query.tokenkey));
    }, leavingMessage() {
      this.$router.push(
        "/leavingMessage?tokenkey=" + this.getEcodeUrl(this.$route.query.tokenkey));
    }, getEcodeUrl(url) {
        if (url) {
          // url地址栏中特殊字符转义
          url = url
          .replace(/\=/g, "%3D")
          .replace(/\+/g, "%2B")
          .replace(/[\s]/g, "%2F")
          .replace(/\?/g, "%3F")
          .replace(/\#/g, "%23")
          .replace(/\&/g, "%26");
        }
        return url;
      },getCserverInfo(val){
       this.cserverInfo=val;
      }
    }
  }
</script>
<style>
  .bottom-title {
    bottom: 0px !important;
  }
</style>
<style scoped lang="scss">

  .iconft {
    font-size: 20px;
    padding-right: 10px;
  }

  .el-header, .el-footer {
    line-height: 60px;
    height: 60px;
    background-color: rgb(26, 19, 167);
    position: absolute;
    z-index: 109;
    width: 100%;
  }

  .el-aside {
    background-color: #D3DCE6;
  }

  .el-container {
    height: 100%
  }

  .right-aside {
    .title {
      line-height: 50px;
      text-align: center;
      border-bottom: 1px #eee solid;
    }
  }

  .el-main.main ::v-deep {
    position: relative;
    padding: 0;
    height: 100%;
    $editor-height: 230px;

    .message-record {
      box-sizing: border-box;
      background-color: white;
      height: calc(100% - #{$editor-height});
      overflow: auto;
      padding: 10px;

      .message-row {
        box-sizing: border-box;
        margin-bottom: 15px;

        &.self {
          text-align: right;

          .message-content {
            &:before {
              left: 100%;
              border: 5px solid transparent;
              border-left-color: #F8f8f8;
            }
          }
        }

        .message-content {
          box-sizing: border-box;
          padding: 0 10px;
          display: inline-block;
          line-height: 2.5;
          border: 1px #eee solid;
          background-color: #F8f8f8;
          border-radius: 3px;
          position: relative;
          margin-top: 5px;

          &:before {
            box-sizing: border-box;
            content: '';
            top: 10px;
            position: absolute;
            right: 100%;
            border: 6px solid transparent;
            border-right-color: #F8f8f8;
          }
        }
      }
    }

    .el-tiptap-editor {
      height: $editor-height;
      border-radius: unset;

      .ProseMirror {
        min-height: 100%;
      }

      .el-tiptap-editor__menu-bar:before {
        margin: 0;
      }

      & > .el-tiptap-editor__content {
        padding: 10px;
        flex: 1;
      }

      .border-top-radius {
        border-radius: unset;
      }
    }
  }

  .links {
    display: flex;
    background-color: white;
    flex-wrap: wrap;
  }

  .link {
    padding: 2px 10px;
    border: 1px solid;
    border-radius: 24px;
    margin: 2px 2px 5px 5px;
    color: #969798;
  }

  .notconn {
    height: 195px;
    background-color: white;
    border-top: 1px solid #e0dede;
    line-height: 195px;
    text-align: center;
    font-size: 16px;
  }

  .editor[data-v-fe3d152e] {
    height: 200px !important;
  }
  .el-form-item {
    margin-bottom: 20px;
  }
  .el-dialog__body {
    padding: 10px 10px;
  }
  .el-form-item__content {
    margin-left: 0px !important;
  }
  .el-container{
    margin-top: 0px !important;
  }
  </style>
